<template>
  <div class="blog_details main_top">
    <div class="blog_content">
        <div class="blog_title">{{blogContent.title}}</div>
        <div class="blog_time">{{ blogContent.createTime }}</div>
        <div v-html="blogContent.content"></div>
    </div>
   
  </div>
</template>

<script>
export default {
    data(){
        return{
            blogId:'',
            blogContent:''
        }
    },
    created(){
        let blogId = this.$route.query.id
        this.blogId = blogId
        this.getBlogInfo()
    },
    methods:{
        getBlogInfo(){
            let blogId = this.blogId
            this.$axiosApi.getBlogInfo({blogId}).then(res=>{
                if(res.code == 200){
                   this.blogContent = res.data
                    
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.blog_content{
    margin-top:16px;
    background:#fff;
    padding:32px;
    border-radius: 4px;
    .blog_title{
        font-size: 32px;
        font-style: normal;
        line-height: 40px;
        color: #363b3e;
        font-weight: 500;
        text-align: left;
    }
    .blog_time{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #a5a6a9;
        padding: 24px 0 16px;
        border-bottom: 1px solid #f2f2f2;
        text-align: left;
    }
}
@media screen and (max-width:768px){
    .blog_details{
       margin:0 -16px;
       .blog_content{
        margin:0!important;
        .blog_title{
            font-size: 20px;
            line-height: 28px;
        }
       }
    }
}

</style>